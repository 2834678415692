import ApolloClient from 'apollo-client'
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-fetch';

const httpLink = new HttpLink({
  uri: 'https://api.emaildrop.io/graphql',
  options: {
    reconnect: true
  },
});

const wsLink = process.browser ? new WebSocketLink({ // if you instantiate in the server, the error will be thrown
  uri: `wss://api.emaildrop.io/subscriptions`,
  options: {
    reconnect: true
  }
}) : null;

export const client = new ApolloClient({
  link: process.browser ? split( //only create the split in the browser
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  ) : httpLink,
  fetch,
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache(),
  ssrMode: true,
});