// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-api-root-types-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/components/apiRootTypes.js" /* webpackChunkName: "component---src-components-api-root-types-js" */),
  "component---src-pages-404-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-api-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-changelogs-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/changelogs.js" /* webpackChunkName: "component---src-pages-changelogs-js" */),
  "component---src-pages-email-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-faq-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tos-js": () => import("/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/mohammadusman/Documents/projects/react/emaildrop-gatsby/.cache/data.json")

